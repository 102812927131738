<div class="main-wrapper">
    <div class="header">
        <div class="header-left">
            <a href="index.html" class="logo">
                <img src="assets/img/logo.png" width="35" height="35" alt> <span>Pre Clinic</span>
            </a>
        </div>
        <a id="toggle_btn" href="javascript:void(0);"><img src="assets/img/icons/bar-icon.svg" alt></a>
        <a id="mobile_btn" class="mobile_btn float-start" href="#sidebar"><img src="assets/img/icons/bar-icon.svg"
                alt></a>
        <div class="top-nav-search mob-view">
            <form>
                <input type="text" class="form-control" placeholder="Search here">
                <a class="btn"><img src="assets/img/icons/search-normal.svg" alt></a>
            </form>
        </div>
        <ul class="nav user-menu float-end">

            <li class="nav-item dropdown has-arrow user-profile-list">
                <a href="#" class="dropdown-toggle nav-link user-link" data-bs-toggle="dropdown">
                    <div class="user-names">
                        <h5 class="text-capitalize">{{userDetail?.firstName}} {{userDetail?.lastName}} </h5>
                        <span class="text-capitalize">{{userRole}}</span>
                    </div>
                    <span class="user-img">
                        <!-- <img src="assets/img/user-06.jpg" alt="Admin" *ngIf="!userDetail?.profilephoto"> -->
                        <img src="assets/img/user.jpg" alt="Admin" *ngIf="!userDetail?.profilephoto">
                        <img [src]="userDetail?.profilephoto" alt="image" *ngIf="userDetail?.profilephoto">
                    </span>
                </a>
                <div class="dropdown-menu">
                    <a class="dropdown-item" routerLink="profile">My Profile</a>
                    <a class="dropdown-item" routerLink="change-password">Change Password</a>
                    <a class="dropdown-item" href="javaScript:;" (click)="onLogOut()">Logout</a>
                </div>
            </li>
            <li class="nav-item d-none">
                <a href="settings.html" class="hasnotifications nav-link"><img
                        src="assets/img/icons/setting-icon-01.svg" alt> </a>
            </li>
        </ul>

    </div>
    <div class="sidebar" id="sidebar">
        <div class="sidebar-inner slimscroll">
            <div id="sidebar-menu" class="sidebar-menu">
                <ul>
                    <li class="menu-title d-none">Main</li>
                    <li class="submenu">
                        <a routerLink="/dashboard" routerLinkActive="active"><span class="menu-side"><img
                                    src="assets/img/icons/menu-icon-01.svg" alt></span>
                            <span> Dashboard </span></a>
                    </li>
                    <li class="submenu">
                        <a routerLink="doctor-shedule" routerLinkActive="active"><span class="menu-side"><img
                                    src="assets/img/icons/menu-icon-05.svg" alt></span>
                            <span> Doctor Schedule </span> </a>
                    </li>
                    <li class="submenu">
                        <a href="javascript:0;" routerLinkActive="active" [ngClass]="showChildLink ? 'subdrop':''" (click)="handleClick()"><span class="menu-side"><img src="assets/img/icons/menu-icon-02.svg"
                                    alt></span>
                            <span> Bed Manegment </span> <span class="menu-arrow"></span></a>
                        <ul [ngClass]="showChildLink ? 'd-block':'d-none'">
                            <li><a routerLink="bed-management/bed-list" routerLinkActive="active">Bed </a></li>
                            <li><a routerLink="bed-management/assign-bed-list" routerLinkActive="active">Bed Assign</a></li>
                        </ul>
                    </li>
                    <li class="submenu">
                        <a routerLink="/patient" routerLinkActive="active"><span class="menu-side"><img
                                    src="assets/img/icons/menu-icon-03.svg" alt></span>
                            <span>Patients </span> </a>
                    </li>
                    <li class="submenu">
                        <a routerLink="/prescription" routerLinkActive="active"><span class="menu-side"><img
                                    src="assets/img/icons/menu-icon-03.svg" alt></span>
                            <span>Prescription </span> </a>
                    </li>
                    <li class="submenu">
                        <a routerLink="/nurse" routerLinkActive="active"><span class="menu-side"><img
                                    src="assets/img/icons/menu-icon-08.svg" alt></span>
                            <span> Nurses </span> </a>

                    </li>
                    <li class="submenu">
                        <a routerLink="/appointment" routerLinkActive="active"><span class="menu-side"><img
                                    src="assets/img/icons/menu-icon-04.svg" alt></span>
                            <span> Appointments </span> </a>
                    </li>
                    <li class="submenu">
                        <a routerLink="/payment" routerLinkActive="active"><span class="menu-side"><img
                                    src="assets/img/icons/menu-icon-09.svg" alt></span>
                            <span> Payment </span> </a>
                    </li>
                </ul>
                <div class="logout-btn">
                    <a href="javaScript:0;" (click)="onLogOut()"><span class="menu-side"><img src="assets/img/icons/logout.svg" alt></span>
                        <span>Logout</span></a>
                </div>
            </div>
        </div>
    </div>
    <div class="page-wrapper">
        <div class="content">
            <router-outlet></router-outlet>
        </div>
    </div>

</div>
<div class="sidebar-overlay" data-reff></div>