import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class DoctorGuard implements CanActivate {
  constructor(private _router: Router) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const getToken: any = localStorage.getItem('token');
    const role: any = JSON.parse(window.atob(getToken.split('.')[1]))["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
    if ((getToken != null && role === 'Doctor') || (getToken != null && role === 'Nurse')) {
      return true; // Allow access
    } else {
      this._router.navigate(['login']);
      return false;
    }
  }
}