import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit{

  resetPassword:FormGroup;
  isValid = false;
  email:any;
  token:any;

  constructor(private _fb: FormBuilder, private toastr: ToastrService,
    private _accountService: AccountService,private _route: ActivatedRoute
    ,private _router: Router){
       this.resetPassword = this._fb.group({
      email: [''],
      token: [''],
      newPassword: ['', [Validators.required,Validators.pattern(
        '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z0-9\d$@$!%*?&].{7,}'
      ),UsernameValidator.cannotContainSpace]],
      confirmPassword: ['', [Validators.required]]
    })
    this.resetPassword.setValidators(passwordMatchValidator('newPassword', 'confirmPassword'));
  }

  public error = (control: string, error: string) => {
    return this.resetPassword.controls[control].hasError(error);
  }

  ngOnInit(): void {
    this._route.queryParams.subscribe((params: any) => {
      this.email = params.email;
      this.token = params.token.replace(/ /g, "\+");
    })
  }

  onResetPassword(){
    this.isValid = true;
    if (this.resetPassword.invalid) {
      return;
    }
     else {
      this.resetPassword.get('email')?.setValue(this.email);
      this.resetPassword.get('token')?.setValue(this.token);
      this._accountService.onResetPassword(this.resetPassword.value).subscribe((res: any) => {
        if (res.status == 'Success' || res.status == 'success') {
          this.toastr.success(res.message);
          this._router.navigate(['login']);
        } else {
          this.toastr.error(res.message);
        }

      })
    }
  }

}

// Validation for password and confirm password //
function passwordMatchValidator(controlName: string, matchingControlName: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const passwordControl = control.get(controlName);
    const confirmPasswordControl = control.get(matchingControlName);

    if (!passwordControl || !confirmPasswordControl) {
      return null; // Return null if controls are not available (e.g., during initialization)
    }

    if (passwordControl.value !== confirmPasswordControl.value) {
      confirmPasswordControl.setErrors({ passwordMismatch: true });
      return { passwordMismatch: true };
    } else {
      confirmPasswordControl.setErrors(null);
      return null;
    }
  };
}


export class UsernameValidator {
  static cannotContainSpace(control: AbstractControl) : ValidationErrors | null {
      if((control.value as string).indexOf(' ') >= 0){
          return {cannotContainSpace: true}
      }

      return null;
  }
}
