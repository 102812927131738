import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(private _http: HttpClient) { }
  
  url = environment.url + 'api/Account/';
  login = 'Login';
  changePassword = 'ChangePassword';
  forgotPassword = 'ForgotPassword?email='
  resetPassword = 'ResetPassword';
  profile = 'GetByIdUser?userId=';
  updateProfile = 'UpdateUser';

  onLogin(data: any) {
    return this._http.post(this.url + this.login, data);
  }

  onChangePassword(data:any){
    return this._http.post(this.url + this.changePassword, data);
  }

  onForgotPassword(email:any){
    return this._http.post(this.url + this.forgotPassword + email , null );
  }

  onResetPassword(data:any){
    return this._http.post(this.url + this.resetPassword, data);
  }
  
  getProfileDetails(userId:string){
    return this._http.get(this.url + this.profile + userId);
  }
  
  onUpdateProfile(data:any){
    return this._http.put(`${this.url}${this.updateProfile}`, data);
  }

}
