import { Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  _accountService = inject(AccountService);
  isValid = false;
  _router = inject(Router);

  login: FormGroup = this._fb.group({
    email: ['', [Validators.required]],
    password: ['', [Validators.required]]
  });

  public error = (control: string, error: string) => {
    return this.login.controls[control].hasError(error);
  }


  constructor(private _fb: FormBuilder, private toastr: ToastrService) { }

  ngOnInit(): void {

  }

  onLogin() {
    this.isValid = true;
    if (this.login.invalid) {
      return;
    }
    else {
      this._accountService.onLogin(this.login.value).subscribe((result: any) => {
        
        if (result.status == "Success" || result.status == "success") {
          this.toastr.success(result.message);
          localStorage.setItem('token', JSON.stringify(result.token));
          const getToken: any = localStorage.getItem('token');
          const role = JSON.parse(window.atob(getToken.split('.')[1]))["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
          // localStorage.setItem('role', role);
          const userName = JSON.parse(window.atob(getToken.split('.')[1]))["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"];
          // localStorage.setItem('userName', userName);
          localStorage.setItem("refreshToken", result.refreshToken);
          if (role == 'Doctor') {
            this._router.navigate(['/dashboard']);
          }
          if(role == 'Nurse'){
            this._router.navigate(['/appointment']);
          }

        } else {
          
          this.toastr.error(result.message);
        }

      }, (error) => {
        const errorLog = error.error;
        this.toastr.error(errorLog?.message);
      })
    }
  }

}
