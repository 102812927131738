import { Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from '../services/account.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit{
  showChildLink = false;
  _router = inject(Router);
  _accountService = inject(AccountService);
  token: any = localStorage.getItem('token');
  userDetail:any = [];
  userId = JSON.parse(window.atob(this.token.split('.')[1]))
  ["http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata"];
  userRole = JSON.parse(window.atob(this.token.split('.')[1]))
  ["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
  

  ngOnInit():void{
    this._accountService.getProfileDetails(this.userId).subscribe((res: any) => {
        this.userDetail = res?.data.user;
    })
  }

  

  handleClick(){
      this.showChildLink =! this.showChildLink;
  }

  
  onLogOut(){
    localStorage.clear();
    this._router.navigate(['login']);
  }

  
}


