import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent {
  forgotPassword: FormGroup;
  isValid = false;

  constructor(private _fb: FormBuilder, private router: Router,
    private _service: AccountService, private toastr: ToastrService) {
    this.forgotPassword = this._fb.group({
      email: ['', [Validators.required]],
    });
  }

  // Handling error
  public error = (control: string, error: string) => {
    return this.forgotPassword.controls[control].hasError(error);
  }

  onForgotPassword() {
    this.isValid = true;
    if (this.forgotPassword.invalid) {
      return;
    } else {
      this._service.onForgotPassword(this.forgotPassword.value.email).subscribe((res: any) => {
        if (res.status === 'Success' || res.status === 'success') {
          this.toastr.success(res.message);
          this.router.navigate(['login']);
        } else {
          this.toastr.error(res.message);
        }
      })
    }

  }



}
