<div class="content container-fluid">
    <div class="page-header">
        <div class="row">
            <div class="col-sm-12">
                <ul class="breadcrumb">
                    <li class="breadcrumb-item"><a routerLink="/header/dashboard">Dashboard </a></li>
                    <li class="breadcrumb-item"><i class="feather-chevron-right"></i></li>
                    <li class="breadcrumb-item active">Change Password</li>
                </ul>
            </div>
        </div>
    </div>


    <div class="row">
        <div class="col-lg-8">
            <div class="card">
                <div class="card-body">
                    <form [formGroup]="changePassword" (ngSubmit)="onChangePassword()">
                        <div class="row">
                            <h4 class="page-title">Change Password</h4>
                            <div class="col-12 col-md-6 col-xl-12">
                                <div class="form-group local-forms">
                                    <label>Old password <span class="login-danger">*</span></label>
                                    <input class="form-control pass-input" type="password" placeholder formControlName="oldPassword">
                                    <span class="profile-views feather-eye-off toggle-password"></span>
                                    <div class="mt-1" *ngIf="isValid && error('oldPassword','required')">
                                        <div class="error-message">
                                            Old password is required.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-xl-6">
                                <div class="form-group local-forms">
                                    <label>New password <span class="login-danger">*</span></label>
                                    <input class="form-control pass-input-new" type="password" placeholder formControlName="newPassword">
                                    <span class="profile-views feather-eye-off new-password" ></span>
                                    <div class="mt-1" *ngIf="isValid && error('newPassword','required')">
                                        <div class="error-message">
                                            New password is required.
                                        </div>
                                    </div>
                                    <div class="mt-1" *ngIf="isValid && error('newPassword','pattern')">
                                        <div class="error-message">
                                            Password should have Minimum 8
                                            Characters atleast 1
                                            uppercase 1 lowercase 1 special character 1 number.
                                        </div>
                                    </div>
                                    <div class="mt-1" *ngIf="isValid && error('newPassword' ,'cannotContainSpace')">
                                        <div class="error-message">
                                            Password is not allowed space.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-xl-6">
                                <div class="form-group local-forms">
                                    <label>Confirm password <span class="login-danger">*</span></label>
                                    <input class="form-control pass-input-confirm" type="password" placeholder
                                        formControlName="confirmPassword">
                                        <span class="profile-views feather-eye-off confirm-password" ></span>
                                    <div class="mt-1" *ngIf="isValid && error('confirmPassword','required')">
                                        <div class="error-message">
                                            Confirm password is required.
                                        </div>
                                    </div>
                                   
                                    <div class="mt-1" *ngIf="error('confirmPassword' ,'passwordMismatch')">
                                        <div class="error-message">
                                            Password must match.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="doctor-submit text-end">
                                    <button type="submit" class="btn btn-primary submit-form me-2"
                                        >Submit</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>