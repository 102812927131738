<div class="main-wrapper login-body">
    <div class="container-fluid px-0">
        <div class="row">

            <div class="col-lg-6 login-wrap">
                <div class="login-sec">
                    <div class="log-img">
                        <img class="img-fluid" src="assets/img/login-02.png" alt="Logo">
                    </div>
                </div>
            </div>


            <div class="col-lg-6 login-wrap-bg">
                <div class="login-wrapper">
                    <div class="loginbox">
                        <div class="login-right">
                            <div class="login-right-wrap">
                                <div class="account-logo">
                                    <a routerLink="/login"><img src="assets/img/login-logo.png" alt></a>
                                </div>
                                <h2>Forgot Password</h2>

                                <form [formGroup]="forgotPassword" (ngSubmit)="onForgotPassword()">
                                    <div class="form-group">
                                        <label>Email <span class="login-danger">*</span></label>
                                        <input class="form-control" type="text" formControlName="email">
                                        <div class="mt-1" *ngIf="isValid && error('email','required')">
                                            <div class="error-message">
                                                Email is required.
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group login-btn">
                                        <button class="btn btn-primary btn-block" type="submit"
                                            >Send Mail</button>
                                    </div>
                                </form>

                                <div class="next-sign">
                                    <p class="account-subtitle">Need an account? <a routerLink="/login">Login</a></p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>