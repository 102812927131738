import { Component, inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators ,ValidatorFn } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent {
  isValid = false;
  _accountService = inject(AccountService);
  _router = inject(Router);
  changePassword: FormGroup;
  token: any = localStorage.getItem('token');
  email = JSON.parse(window.atob(this.token.split('.')[1]))["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"];
  
  constructor(private _fb: FormBuilder, private toastr: ToastrService) {

    this.changePassword = this._fb.group({
      email:[''],
      oldPassword: ['', Validators.required],
      newPassword: ['', [Validators.required, Validators.pattern(
        '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z0-9\d$@$!%*?&].{7,}'
      ), UsernameValidator.cannotContainSpace]],
      confirmPassword: ['', Validators.required],
    });

    this.changePassword.setValidators(passwordMatchValidator('newPassword', 'confirmPassword'));

  }

  public error = (control: string, error: string) => {
    return this.changePassword.controls[control].hasError(error);
  }


  onChangePassword() {
    this.isValid = true;
    if (this.changePassword.invalid) {
      return;
    }
     else {
      this.changePassword.get('email')?.setValue(this.email);
      this._accountService.onChangePassword(this.changePassword.value).subscribe((res: any) => {
        if (res.status == 'Success' || res.status == 'success') {
          this.toastr.success(res.message);
          this.refreshComponent();
        } else {
          this.toastr.error(res.message);
        }

      })
    }
  }



  // For refresh component without page load
  refreshComponent() {
    const currentUrl = this._router.url;
    this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this._router.navigate([currentUrl]);
    });
  }
}

// Validation for password and confirm password //
function passwordMatchValidator(controlName: string, matchingControlName: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const passwordControl = control.get(controlName);
    const confirmPasswordControl = control.get(matchingControlName);

    if (!passwordControl || !confirmPasswordControl) {
      return null; // Return null if controls are not available (e.g., during initialization)
    }

    if (passwordControl.value !== confirmPasswordControl.value) {
      confirmPasswordControl.setErrors({ passwordMismatch: true });
      return { passwordMismatch: true };
    } else {
      confirmPasswordControl.setErrors(null);
      return null;
    }
  };
}


export class UsernameValidator {
  static cannotContainSpace(control: AbstractControl): ValidationErrors | null {
    if ((control.value as string).indexOf(' ') >= 0) {
      return { cannotContainSpace: true }
    }

    return null;
  }
}
