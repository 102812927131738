<div class="main-wrapper login-body">
    <div class="container-fluid px-0">
        <div class="row">

            <div class="col-lg-6 login-wrap">
                <div class="login-sec">
                    <div class="log-img">
                        <img class="img-fluid" src="assets/img/login-02.png" alt="Logo">
                    </div>
                </div>
            </div>


            <div class="col-lg-6 login-wrap-bg">
                <div class="login-wrapper">
                    <div class="loginbox">
                        <div class="login-right">
                            <div class="login-right-wrap">
                                <div class="account-logo">
                                    <a routerLink="/login"><img src="assets/img/login-logo.png" alt></a>
                                </div>
                                <h2>Reset Password</h2>

                                <form [formGroup]="resetPassword" (ngSubmit)="onResetPassword()">
                                    <div class="col-12 col-md-6 col-xl-12">
                                        <div class="form-group local-forms">
                                            <label>New password <span class="login-danger">*</span></label>
                                            <input class="form-control pass-input" type="password" placeholder formControlName="newPassword">
                                            <span class="profile-views feather-eye-off toggle-password"></span>
                                            <div class="mt-1" *ngIf="isValid && error('newPassword','required')">
                                                <div class="error-message">
                                                    New password is required.
                                                </div>
                                            </div>
                                            <div class="mt-1" *ngIf="isValid && error('newPassword','pattern')">
                                                <div class="error-message">
                                                    Password should have Minimum 8
                                                    Characters atleast 1
                                                    uppercase 1 lowercase 1 special character 1 number.
                                                </div>
                                            </div>
                                            <div class="mt-1" *ngIf="isValid && error('newPassword' ,'cannotContainSpace')">
                                                <div class="error-message">
                                                    Password is not allowed space.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6 col-xl-12">
                                        <div class="form-group local-forms">
                                            <label>Confirm password <span class="login-danger">*</span></label>
                                            <input class="form-control pass-input-new" type="password" placeholder formControlName="confirmPassword">
                                            <span class="profile-views feather-eye-off new-password" ></span>
                                            <div class="mt-1" *ngIf="isValid && error('confirmPassword','required')">
                                                <div class="error-message">
                                                    Confirm password is required.
                                                </div>
                                            </div>
                                            <div class="mt-1" *ngIf="error('confirmPassword' ,'passwordMismatch')">
                                                <div class="error-message">
                                                    Password must match.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group login-btn">
                                        <button class="btn btn-primary btn-block" type="submit" >Reset Password</button>
                                    </div>
                                </form>

                                <div class="next-sign">
                                    <p class="account-subtitle">Need an account? <a routerLink="/login">Login</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>