import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ChangePasswordComponent } from './account/change-password/change-password.component';
import { ForgotPasswordComponent } from './account/forgot-password/forgot-password.component';
import { LoginComponent } from './account/login/login.component';
import { ResetPasswordComponent } from './account/reset-password/reset-password.component';
import { DoctorGuard } from './guards/doctor.guard';
import { NurseGuard } from './guards/nurse.guard';
import { HeaderComponent } from './header/header.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent, title: 'Login' },
  { path: 'forgot-password', component: ForgotPasswordComponent, title: 'Forgot Password' },
  { path: 'reset-password', component: ResetPasswordComponent, title: 'Reset Password' },
  { path: 'header', component: HeaderComponent },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: '', component: HeaderComponent, canActivate: [DoctorGuard], children: [
      { path: 'change-password', title: 'Change Password', component: ChangePasswordComponent },
      { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule), title: 'Dashboard' },
      { path: 'profile', loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule), title: 'Profile' },
      { path: 'patient', loadChildren: () => import('./patient/patient.module').then(m => m.PatientModule), title: 'Patients' },
      { path: 'prescription', loadChildren: () => import('./prescription/prescription.module').then(m => m.PrescriptionModule), title: 'Prescription' },
      { path: 'appointment', loadChildren: () => import('./appointment/appointment.module').then(m => m.AppointmentModule), title: 'Appointment' },
      { path: 'nurse', loadChildren: () => import('./nurse/nurse.module').then(m => m.NurseModule), title: 'Nurse' },
      { path: 'payment', loadChildren: () => import('./payment/payment.module').then(m => m.PaymentModule), title: 'Payment' },
      { path: 'doctor-shedule', loadChildren: () => import('./doctor/doctor.module').then(m => m.DoctorModule), title: 'Doctor Shedule' },
      { path: 'bed-management', loadChildren: () => import('./bed-management/bed-management.module').then(m => m.BedManagementModule), title: 'Bed Management' },
    ]
  },
  { path: "**", redirectTo: 'login', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
